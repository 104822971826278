<template>
  <div class="login">
    <div class="login-container-img">
      <img src="./image/pc.jpg" v-if="$store.state.pageset.pcshow" />
      <img src="./image/moblie.jpg" v-else />
    </div>
    <div class="login-container">
      <div class="login-container-form" v-loading.fullscreen.lock="loading">
        <h1>登录</h1>
        <el-form ref="form" :model="form" v-if="!isSms">
          <el-form-item>
            <p class="p-normal">账号（手机号码）</p>
            <el-input
              v-model="form.username"
              autofocus=""
              placeholder="请输入手机号"
              @keyup.enter.native="onSubmit"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <p class="p-normal">密码</p>
            <el-input
              v-model="form.password"
              type="password"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="none"
              aria-label="password"
              required=""
              placeholder="请输入密码"
              @keyup.enter.native="onSubmit"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-form ref="form" :model="smsForm" v-else>
          <el-form-item>
            <p class="p-normal">手机号</p>
            <el-input v-model="smsForm.phone" autofocus=""></el-input>
          </el-form-item>
          <el-form-item>
            <p class="p-normal">验证码</p>
            <el-input v-model="smsForm.code" required="">
              <el-button
                :loading="smsLoading"
                :disabled="getCodeDisabled"
                slot="append"
                class="input-btn"
                @click="sendSms"
              >
                {{ getCodeBtnText }}</el-button
              >
            </el-input>
          </el-form-item>
        </el-form>

        <div v-if="isSms" class="sign-bottom-right" @click="changeType">
          <p class="p1">使用账号密码登录</p>
          <p class="p2">Log in via password</p>
        </div>
        <div v-else class="sign-bottom-right" @click="changeType">
          <p class="p1">使用验证码登录</p>
          <p class="p2">Log in via SMS</p>
        </div>
        <button type="submit" class="default-btn btn-submit" @click="onSubmit">
          登录
        </button>
        <button class="default-btn btn-tertiary" @click="cancelLogin">取消</button>
        <span class="linebreak">或</span>
        <button class="default-btn btn-tertiary register-btn" @click="openRegister">
          创建账户
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import index from "./index.js";

export default index;
</script>
<style scoped lang="scss">
@import "index.scss";
</style>
