import { isPhone } from '@/utils/index';
import { sendSms, login, getInfo,loginSms } from '@/api/login';
import { setToken } from '@/utils/auth';
export default {
  components: {
  },
  data() {
    return {
      form: {
        username: '',//手机号
        password: '',//密码
      },
      smsForm: {
        phone: '',
        code: ''
      },
      focusIndex: null,//当前聚焦元素
      isSms: false,
      getCodeBtnText: '获取验证码',
      getCodeDisabled: true,
      timer: null,
      smsLoading:false,
      loading:false,
      countDownTime:60
    }
  },
  watch: {
    $route: {
      handler(route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true,
    },
  },
  created() {
    const sendEndTime = localStorage.getItem('sms_start_time_ver_code');
    if (sendEndTime) {
      this.smsLoading = true;
      this.countdown();
      return;
    }
    this.getCodeDisabled = false;
  },
  deactivated() {
  },
  methods: {
    openRegister() {
      this.$router.push('/register');
    },
    async onSubmit() {
      const { form, smsForm, isSms } = this;
      let res = {};
      let params = {};
      if (isSms) {
        if (!smsForm.phone || !smsForm.code) {
          this.$message.info('请输入手机号/验证码');
          return;
        }
        params = smsForm;
        res = await loginSms(params);
      } else {
        if (!form.username || !form.password) {
          this.$message.info('请输入账户名/密码');
          return;
        }
        params = form;
        res = await login(params);
      }
      this.loading = true;
      if (res.code === 200) {
        // 获取用户信息，写入缓存
        setToken(res.data.access_token);
        let resUser = await getInfo(res.data.access_token);
        if (resUser.code === 200) {
          resUser.user.roleIds= resUser.roles;
          this.$router.push({ path: this.redirect || '/' });
        }
        this.loading = false;
      }else{
        this.loading = false;
      }
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
    changeType() {
      this.isSms = !this.isSms;
    },
    forgotPsd() {
      this.$message.info('请联系管理员重置');
    },
    async sendSms() {
      if (!isPhone(this.smsForm.phone)) {
        this.$message.info('请输入正确手机号');
        return;
      }
      this.smsLoading = true;
      try {
        await sendSms(this.smsForm.phone);
        this.$message.success('验证码已发送');
        this.countdown();
      } catch (e) {
        this.$message.error('验证码发送失败，请联系管理员');
      }
    },
    countdown() {
      const that = this;
      const startTime = localStorage.getItem('sms_start_time_ver_code');
      const now = new Date().getTime();
      if (startTime) {
        const surplus = 60 - parseInt((now - startTime) / 1000, 10);
        this.countDownTime = surplus <= 0 ? 0 : surplus;
      } else {
        this.countDownTime = 60;
        localStorage.setItem('sms_start_time_ver_code', now);
      }
      that.timer = setInterval(() => {
        that.countDownTime--;
        that.getCodeBtnText = `${that.countDownTime}秒后重试`;
        that.getCodeDisabled = true;
        that.smsLoading = false;
        if (that.countDownTime <= 0) {
          localStorage.removeItem('sms_start_time_ver_code');
          clearInterval(that.timer);
          that.countDownTime = 60;
          that.getCodeBtnText = '获取验证码';
          that.getCodeDisabled = false;
        }
      }, 1000);
    },
    cancelLogin() {
      this.$router.push('/');
    }
  }
}